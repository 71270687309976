import forEach from '../../../../javascripts/dom/forEach';

const firstScreen = document.querySelectorAll('[data-js-module="o-first-screen"]');
let inited;

const init = async () => {
  inited = true;
  const { default: FirstScreen } = await import(
    /* webpackChunkName: "o-first-screen" */ './first-screen'
  );

  forEach(firstScreen, (index, element) => new FirstScreen(element));
};

document.body.addEventListener('toggle-slide', ({ detail }) => {
  if(detail.slideId === 'main' && !inited && firstScreen.length) {
    init();
  }
});
