import BaseModule from '../../../../javascripts/helpers/baseModule';
import audioRecorderService from '../../../../javascripts/helpers/audio-recorder.service';

const activeClass = 'o-slide--active';
const hiddenClass = 'o-slide--hidden';

export default class Slide extends BaseModule {
  constructor(element) {
    super(element, element.getAttribute('data-js-module'));

    const slideId = element.getAttribute('data-js-id');

    document.body.addEventListener('fade-out-slide', ({ detail }) => {
      if (slideId === detail.slideId) {
        this.elements.self.classList.add(hiddenClass);
      }
    });

    document.body.addEventListener('toggle-slide', ({ detail }) => {
      if (this.isActiveSlide) {
        element.setAttribute('previous-slide', 'true');
        audioRecorderService.stopAudio();
        this.elements.self.classList.remove(activeClass);
      } else {
        element.removeAttribute('previous-slide');
      }

      if (slideId === detail.slideId && slideId === '3dtour') {
        const iframe = document.querySelector('iframe');
        if (iframe && !iframe.getAttribute('src')) {
          iframe.setAttribute(
            'src',
            'https://my.matterport.com/show/?m=Rn9zpFgBHCo&play=1&fullscreen'
          );
        }
      }

      if (slideId === detail.slideId) {
        document.body.style.background = this.elements.self.style.background;
        this.elements.self.classList.remove(hiddenClass);
        const ripple = this.elements.self.querySelector('.ripple');

        if (ripple) {
          this.elements.self.removeChild(ripple);
        }

        setTimeout(() => {
          this.elements.self.classList.add(activeClass);
        }, 100);
      }
    });
  }

  get isActiveSlide() {
    return this.elements.self.classList.contains(activeClass);
  }
}
