import BaseModule from '../../../../javascripts/helpers/baseModule';
import audioRecorderService from '../../../../javascripts/helpers/audio-recorder.service';
import GtagService from '../../../../javascripts/helpers/gtag.service';
import axios from 'axios';
import router from '../../../../javascripts/helpers/router';

export default class ThankYou extends BaseModule {
  constructor(element) {
    super(element, element.getAttribute('data-js-module'));

    this.gtag = null;
    this.noAudio = false;
    this.audioList = [];
    this.audioCounter = 0;
    this.isAudioPlaying = false;
    this.isCreatedAudio = false;
    this.totalAudio = 0;
    this.totalText = 0;
    this.currentAudio = 1;
    this.currentAudioPage = 1;
    this.textCurrentPage = 1;

    


    this.getElements([
      { id: 'listenButton', selector: 'listen-button' },
      { id: 'listenButtonPrev', selector: 'listen-button-prev' },
      { id: 'listenButtonNext', selector: 'listen-button-next' },
      { id: 'restartButton', selector: 'restart-button' },
      { id: 'toAbout', selector: 'to-about' },
      { id: 'progress', selector: 'progress' },
      { id: 'content', selector: 'content' },
      { id: 'usersText', selector: 'users-text' },
      { id: 'usersTextWrapper', selector: 'users-text-wrapper' },
      { id: 'animation', selector: 'animation' },
      { id: 'animationWrapper', selector: 'animation-wrapper' },
      { id: 'loadMoreText', selector: 'load-more-text' },
      { id: 'totalAudio', selector: 'total_audio' },
      { id: 'currentAudio', selector: 'current_audio' },

      { id: 'buttons', selector: 'buttons' }
    ]);

    document.body.addEventListener('toggle-slide', ({ detail }) => {
      if (!this.gtag) {
        this.gtag = new GtagService();
      }

      if (detail.slideId !== 'list') {
        const { content, progress, animation, animationWrapper } = this.elements;
        const header = document.body.querySelector('.header');
        progress.style.width = 0 + '%';
        header.style.display = 'none';
        content.style.opacity = '0';
        animation.style.display = 'block';
        animationWrapper.style.display = 'flex';
        header.style.display = 'flex';
        return;
      }

      if (detail.slideId === 'list') {
        this.gtag.page('/list');
        this.allAudio();
        this.usersText();
      }

      if(detail.passAnimation) {
        this.noAudio = true;
        this.onAnimated();
      } else {
        this.thankYouProgress();
      }
    });

    this.initListeners();
  }

  initListeners() {
    const { listenButton, restartButton, toAbout, buttons,listenButtonNext,listenButtonPrev, loadMoreText } = this.elements;

    listenButton.addEventListener('click', this.listenAudio.bind(this));
    listenButtonNext.addEventListener('click', this.nextAudio.bind(this));
    listenButtonPrev.addEventListener('click', this.prevAudio.bind(this));
    loadMoreText.addEventListener('click', this.loadMoreText.bind(this));

    // restartButton.addEventListener('click', this.restartRecorder.bind(this));
    toAbout.addEventListener('click', this.toAbout.bind(this));
    document.body.addEventListener('no-audio', () => {
      this.noAudio = true;
      buttons.classList.remove('invisible');
    });
    document.body.addEventListener('with-audio', () => {
      this.noAudio = false;
      buttons.classList.remove('invisible');
    });
    this.elements.currentAudio.innerHTML = this.currentAudio
   
  }

  

  listenAudio() {
    if (this.noAudio){
      if(this.audioList.length){
        this.audioList[this.audioCounter].play();
      }

    } else {
      if(this.audioList.length){
        if(!this.isCreatedAudio){
          this.isCreatedAudio = true;
          audioRecorderService.createAudio();
          this.audioList.unshift(audioRecorderService.audio);
        }
        this.audioList[this.audioCounter].play();

      }
    }
    this.gtag.trackEvent('record', 'btn_play');
    if(this.isAudioPlaying){
      this.isAudioPlaying = false;
    }

  }
  nextAudio(){
    this.elements.listenButtonPrev.classList.remove('dissabled');
    if(this.audioCounter + 1 === this.audioList.length){
      return 
    }
    if(this.audioCounter + 2 === this.audioList.length){
      this.elements.listenButtonNext.classList.add('dissabled');
    }
    if(this.audioList.length){
      if(this.isAudioPlaying){
        this.audioList[this.audioCounter].pause();
      }
      this.audioCounter += 1;
      this.currentAudio += 1;
      this.elements.currentAudio.innerHTML = this.currentAudio;
      this.audioList[this.audioCounter].play();
      this.isAudioPlaying = true;

    }
    if(this.audioCounter === 10 * this.currentAudioPage - 5 && this.currentAudioPage != Math.ceil(this.totalAudio / 10)){
      this.allAudio(this.currentAudioPage + 1)
    }
  }
  prevAudio(){
    this.elements.listenButtonNext.classList.remove('dissabled');
    if(this.audioCounter <= 0){
      return
    }
    if(this.audioCounter === 1){
      this.elements.listenButtonPrev.classList.add('dissabled');
    }

    if(this.audioList.length){
      if(this.isAudioPlaying){
        this.audioList[this.audioCounter].pause();
      }

      this.currentAudio -= 1
      this.elements.currentAudio.innerHTML = this.currentAudio
      this.audioCounter -= 1;
      this.audioList[this.audioCounter].play();

    }
  }

  allAudio(page = 1){
    axios
    .get(`${process.env.API_URL}/record`, {params: {
      'per-page': 10,
      page
    }})
    .then(({data}) => {
      this.totalAudio = data.total
      this.currentAudioPage = page     
      this.elements.totalAudio.innerHTML = this.totalAudio
      if(this.isCreatedAudio){
        this.elements.totalAudio.innerHTML = this.totalAudio + 1
      }
      return data.items.map(item => new Audio(`${process.env.APP_URL}/sound/${item.hash.substring(0, 2)}/${item.hash}.wav`))
    })
    .then((res) => this.audioList = [...this.audioList, ...res])
  
  }
  usersText(page = 1){
    axios.get(`${process.env.API_URL}/text`, 
    {params: {
       'per-page': 10,
       page
     }})
       .then(({data}) => {
         this.totalText = data.total;
         this.textCurrentPage = page
        return data.items.map(el => {
          let div = document.createElement("div")
          div.innerHTML = el.text
          this.elements.usersTextWrapper.appendChild(div).classList.add('o-thank-you__texts-item')
        });
       })
     }
   
   loadMoreText(){
    if(this.textCurrentPage != Math.ceil(this.totalText / 10)){
     this.usersText(this.textCurrentPage + 1)
     this.elements.loadMoreText.style.opacity = 1
     this.elements.loadMoreText.style.visibility = 'visible'
   } 
   if(this.textCurrentPage + 1 === Math.ceil(this.totalText / 10)){
    this.elements.loadMoreText.style.opacity = 0
    this.elements.loadMoreText.style.visibility = 'hidden'
}
  }
 

  restartRecorder() {
    if (this.noAudio) return;
    this.gtag.trackEvent('record', 'btn_retry');
    router.push('record');
  }

  toAbout() {
    router.push('about');
  }


  thankYouProgress() {
    const { content, progress, animation, animationWrapper } = this.elements;
    const header = document.body.querySelector('.header');
    let width = 1;
    let id = setInterval(() => {
      if (width >= 100) {
        clearInterval(id);
        this.onAnimated();
      } else {
        width++;
        progress.style.width = width + '%';
        header.style.display = 'none';
      }
    }, 50);
  }

  onAnimated() {
    const { content, animation, animationWrapper } = this.elements;
    const header = document.body.querySelector('.header');
    content.style.opacity = '1';
    animation.style.display = 'none';
    animationWrapper.style.display = 'none';
    header.style.display = 'flex';
  }
}
