/**
 * Function to get a cookie with specific name
 * @param {string} name - a name of cookie to get
 * @returns {any|null} - returns value of cookie with specified name
 * @example
 * getCookie(token);
 */
const getCookie = name => {
  const cookie = `; ${document.cookie}`;
  const parts = cookie.split(`; ${name}=`);
  return parts.length === 2
    ? parts
        .pop()
        .split(';')
        .shift()
    : null;
};

/**
 * Function to update the options, with expires date as a date UTC string, if expires is passed in options
 * @param {Object} options - options object
 * @return {Object} - return either modified options or original options object
 * @example
 * setExpirationDate({ foo: bar, expires: 60 });
 */
const setExpirationDate = ({ expires, ...options }) => {
  let updatedOptions = options;
  if (typeof expires === 'number' && expires) {
    const date = new Date();
    date.setTime(date.getTime() + expires * 1000);
    const expirationDate = date.toUTCString();
    updatedOptions = { ...options, expires: expirationDate };
  }
  return updatedOptions;
};

/**
 * Function to generate a cookie string from base cookie string and options object
 * @param {string} cookie - base cookie string, consists of name=value
 * @param {Object} options - options for the cookie
 * @return {String} cookie string
 * @example
 * generateCookie('token=123test', { expires: "Thu, 10 May 2018 10:44:37 GMT" });
 */
const generateCookie = (cookie, options) =>
  Object.entries(options).reduce((acc, [key, value]) => `${acc};${key}=${value}`, cookie);

/**
 * Function to set cookie to document. Calls {@link generateCookie} to generate cookie to set to document
 * @param {String} name - Cookie name
 * @param {any} value - Cookie value
 * @param {Object} options - various cookie options object. Gets updated with {@link setExpirationDate}
 * @return {undefined}
 * @example
 * setCookie('token', '123test', { expires: 60 });
 */
const setCookie = (name, value, options = { path: '/' }) => {
  const cookie = `${name}=${value}`;
  document.cookie = generateCookie(cookie, setExpirationDate(options));
};

/**
 * Function to remove document cookie by name, passed in param.
 * Calls {@link setCookie} and sets expiration to past
 * @param {String} name - cookie name
 * @example
 * @return {undefined}
 * removeCookie('token')
 */
const removeCookie = name => setCookie(name, { expires: -1 });

/**
 * Function to clear all cookies, set by client side js
 * @return {undefined}
 */
const clearCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.map(cookie => cookie.split('=')[0]).map(removeCookie);
};

export default {
  getCookie,
  setCookie,
  removeCookie,
  clearCookies
};
