import BaseModule from '../../../../javascripts/helpers/baseModule';
import Cookie from '../../../../javascripts/helpers/cookies';
import removeClass from '../../../../javascripts/dom/removeClass';
import addClass from '../../../../javascripts/dom/addClass';

const cookieLayer = document.querySelector('[data-js-module="m-cookie-layer"]');

const COOKIE_NAME = 'cookies-accepted';
const EXPIRATION = 365 * 24 * 60 * 60 * 1000;

export default class CookieLayer extends BaseModule {
  constructor(element) {
    super(element, 'm-cookie-layer');

    this.getElements([
      {
        selector: 'close-button',
        id: 'closeButton'
      }
    ]);

    // Initial check is delayed in order to wait a bit for html render and smooth animation
    setTimeout(() => !CookieLayer.isCookieSet && this.showLayer(), 500);
    this.initListeners();
  }

  static get isCookieSet() {
    return Cookie.getCookie(COOKIE_NAME);
  }

  showLayer() {
    const { self } = this.elements;
    removeClass(self, `${this.id}--hidden`);
    setTimeout(() => self.setAttribute('aria-hidden', 'false'), 0);
  }

  hideLayer() {
    const { self } = this.elements;
    self.setAttribute('aria-hidden', 'true');
    setTimeout(() => addClass(self, `${this.id}--hidden`), 150);
  }

  initListeners() {
    const onCloseButtonClick = e => {
      e.preventDefault();

      Cookie.setCookie(COOKIE_NAME, true, { expires: EXPIRATION, path: '/' });
      this.hideLayer();
    };

    this.elements.closeButton.addEventListener('click', onCloseButtonClick);
  }
}

if (cookieLayer) new CookieLayer(cookieLayer);
