import forEach from '../../../../javascripts/dom/forEach';

const aboutPage = document.querySelectorAll('[data-js-module="o-about"]');

let inited = false;

const init = async () => {
  inited = true
  const { default: About } = await import(/* webpackChunkName: "o-about" */ './about');

  forEach(aboutPage, (index, element) => new About(element));
};

document.body.addEventListener('toggle-slide', ({ detail }) => {
  if(detail.slideId === 'about' && !inited && aboutPage.length) {
    init();
  }
});
