import select from '../dom/select';

class BaseModule {
  constructor(element, id) {
    this.id = id;
    this.elements = {
      self: element
    };

    this.setInitialized();
  }

  setInitialized() {
    if (this.elements.self) {
      this.elements.self.setAttribute('data-js-module-initialized', true);
    }
  }

  setUnInitialized() {
    if (this.elements.self) {
      this.elements.self.removeAttribute('data-js-module-initialized');
    }
  }

  /**
   * Selects DOMElements of a module with a specific data attribute (`data-js-element="${module}__${selector}"`)
   * Receives an object where the id param is the key in the elements cache
   * The selector param of the config is the selector part in the query-selector described above
   * @param {Object} config Configuration object in a key value format
   * @returns {void}
   */
  getElements(config) {
    const elementsConfig = config.map(elementConfig => ({
      ...elementConfig,
      selector: `${this.id}__${elementConfig.selector}`
    }));

    const domElements = select('[data-js-element]', this.elements.self);

    for (let i = 0; i < domElements.length; i += 1) {
      const domElement = domElements[i];
      const elementConfigCandidates = elementsConfig.filter(
        singleConfig => domElement.getAttribute('data-js-element') === singleConfig.selector
      );

      const [elementConfig] = elementConfigCandidates;

      if (elementConfig) {
        if (typeof this.elements[elementConfig.id] === 'undefined') {
          this.elements[elementConfig.id] = domElement;
        } else {
          if (typeof this.elements[elementConfig.id].push === 'undefined') {
            this.elements[elementConfig.id] = [this.elements[elementConfig.id]];
          }
          this.elements[elementConfig.id].push(domElement);
        }
      }
    }

    /*
     * Append a attribute "asList" to each element/s in this.elements
     * which is always an array no matter if element/s is an array
     * or a single HTMLElement
     */

    const asList = obj => (typeof obj.push === 'undefined' ? [obj] : obj);
    Object.values(this.elements).forEach(obj => {
      obj.asList = asList(obj); // eslint-disable-line no-param-reassign
    });
  }
}

export default BaseModule;
