/**
 * Triggers custom events
 *
 * @param {Object} el Object the event is bound to
 * @param {string} eventName Name of the custom event
 * @param {Object} data Data for the event which is passed in the detail property of the event
 * @returns {void}
 */
export default (el, eventName, data = {}) => {
  const event = new CustomEvent(eventName, {
    detail: data
  });
  el.dispatchEvent(event);
};
