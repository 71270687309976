export class GtagService {
  constructor() {
    this.$gtag = gtag;
    this.trackingCache = {};
    this.trackingId = 'UA-150539426-3';
  }

  page(to) {
    console.log('TRACKING PAGE ->', to);
    this.$gtag('config', this.trackingId, {
      page_path: to,
      location_path: `${window.location.origin}${to}`
    });
  }

  trackEvent(event_category, event_action, event_label, event_value) {
    if (!event_category) event_category = 'common';

    console.log(
      `TRACKING EVENT -> ${event_action}, ${JSON.stringify({
        event_category,
        event_label,
        event_value
      })}`
    );
    this.$gtag('event', event_action, {
      event_category,
      event_label,
      event_value
    });
  }

  trackEventOnce(event_category, event_action, event_label, event_value) {
    const key = `${event_category}:${event_action}`;
    if (this.trackingCache[key]) {
      return;
    } else {
      this.trackEvent(event_category, event_action, event_label, event_value);
      this.trackingCache[key] = true;
    }
  }
}

export default GtagService;
