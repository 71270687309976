import forEach from '../../../../javascripts/dom/forEach';

const header = document.querySelectorAll('[data-js-module="o-header"]');

const init = async () => {
  const { default: Header } = await import(/* webpackChunkName: "o-header" */ './header');

  forEach(header, (index, element) => new Header(element));
};

if (header.length) init();
