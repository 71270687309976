import forEach from '../../../../javascripts/dom/forEach';
import ThankYou from './thank-you';

const audioRecorders = document.querySelectorAll('[data-js-module="o-audio-recorder"]');

let inited = false;

const loadThankYouPage = async () => {
  const thankYou = document.querySelectorAll('[data-js-module="o-thank-you"]');
  forEach(thankYou, (index, element) => new ThankYou(element));
};

const init = async () => {
  inited = true;
  const { default: AudioRecorder } = await import(
    /* webpackChunkName: "o-audio-recorder" */ './audio-recorder'
  );

  forEach(audioRecorders, (index, element) => new AudioRecorder(element));
};

document.body.addEventListener('toggle-slide', ({ detail }) => {
  if(detail.slideId === 'record' && !inited && audioRecorders.length) {
    init();
  }
});

loadThankYouPage();
