import forEach from '../../../../javascripts/dom/forEach';

const adm = document.querySelectorAll('[data-js-module="o-adm"]');

let inited = false;

const init = async () => {
  inited = true
  const { default: Adm } = await import(/* webpackChunkName: "o-adm" */ './adm');

  forEach(adm, (index, element) => new Adm(element));
};

document.body.addEventListener('toggle-slide', ({ detail }) => {
  if(detail.slideId === 'adm' && !inited && adm.length) {
    init();
  }
});
