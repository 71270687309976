import triggerCustom from '../events/triggerCustom';

export class Router {
  push(url, params = {}, query = {}) {
    let slideId = url;
    console.log('URL ->', url, 'QUERY ->', query);

    if (url === '/') {
      slideId = 'main';
    }
    window.history.pushState(
      { slideId: url },
      url,
      Object.entries(query).reduce(
        (acc, [key, value], i) => acc + `${i === 0 ? '?' : '&'}${key}=${value}`,
        url
      )
    );
    triggerCustom(document.body, 'toggle-slide', { slideId, ...params });
  }

  replace(url) {
    window.history.replaceState({ slideId: url }, url, url);
    triggerCustom(document.body, 'toggle-slide', { slideId: url });
  }
}

export default new Router();
