import forEach from '../../../../javascripts/dom/forEach';

const statistic = document.querySelectorAll('[data-js-module="o-statistic"]');

let inited = false;

const init = async () => {
  inited = true
  const { default: Statistic } = await import(/* webpackChunkName: "o-statistic" */ './statistic');

  forEach(statistic, (index, element) => new Statistic(element));
};

document.body.addEventListener('toggle-slide', ({ detail }) => {
  if(detail.slideId === 'statistic' && !inited && statistic.length) {
    init();
  }
});
