/**
 * Adds classes to a DOM element
 * @param {Element} element Element the classes should get added to
 * @param {string} classNames One or more classnames
 * @returns {void}
 */
export default (element, ...classNames) => {
  classNames
    .filter(className => !element.classList.contains(className))
    .forEach(className => {
      element.classList.add(className);
    });
};
