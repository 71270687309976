import forEach from '../../../../javascripts/dom/forEach';

const accordions = document.querySelectorAll('[data-js-module="o-accordion"]');

let inited;

const init = async () => {
  inited = true;
  const { default: Accordion } = await import(/* webpackChunkName: "o-accordion" */ './accordion');

  forEach(accordions, (index, element) => new Accordion(element));
};

document.body.addEventListener('toggle-slide', ({ detail }) => {
  if(detail.slideId === 'stereotypes' && !inited && accordions.length) {
    init();
  }
});
