import forEach from '../../../../javascripts/dom/forEach';

const admSound = document.querySelectorAll('[data-js-module="o-adm-sound"]');

let inited = false;

const init = async () => {
  inited = true
  const { default: AdmSound } = await import(/* webpackChunkName: "o-adm-sound" */ './adm-sound');

  forEach(admSound, (index, element) => new AdmSound(element));
};

document.body.addEventListener('toggle-slide', ({ detail }) => {
  if(detail.slideId === 'adm/sound' && !inited && admSound.length) {
    init();
  }
});
