import forEach from '../../../../javascripts/dom/forEach';

const socialEmbeds = document.querySelectorAll('[data-js-module="m-social-embed"]');

const init = async () => {
  const { default: SocialEmbed } = await import(
    /* webpackChunkName: "m-social-embed" */ './social-embed'
  );

  forEach(socialEmbeds, (index, element) => new SocialEmbed(element));
};

if (socialEmbeds.length) init();
