// Shims
// import './shims/closest';
import forEach from './dom/forEach';
import triggerCustom from './events/triggerCustom';
import GtagService from './helpers/gtag.service.js';
import router from './helpers/router';

// all links with target="_blank" attribute should hav an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
forEach(document.querySelectorAll("[target='_blank']"), (index, link) => {
  link.setAttribute('rel', 'noopener');
});

function resetHeight() {
  document.body.style.height = `${window.innerHeight}px`;
}

window.addEventListener('resize', resetHeight);

resetHeight();

const isDesktop = () => window.matchMedia('(min-width: 1024px)').matches;

const src = isDesktop() ? '/dummy/images/Intro-copy.jpg' : '/dummy/images/intro_small-copy.png';
const intro = document.querySelector('.o-intro');

const currentPathName = window.location.pathname.replace('/', '');

window.addEventListener('popstate', ({ state }) => {
  let slideId;

  if (!state) {
    const currentPathName = window.location.pathname.replace('/', '');

    if (currentPathName === '') {
      slideId = 'main';
    } else {
      slideId = currentPathName;
    }
    triggerCustom(document.body, 'toggle-slide', { slideId });
  } else {
    slideId = state.slideId;

    if (slideId === '/') {
      slideId = 'main';
    }
    triggerCustom(document.body, 'toggle-slide', { slideId });
  }
});

if (currentPathName !== '' && currentPathName !== 'record') {
  setTimeout(() => {
    const gtag = new GtagService();
    if (currentPathName === 'list') {
      triggerCustom(document.body, 'toggle-slide', {
        slideId: currentPathName,
        passAnimation: true
      });
    } else {
      triggerCustom(document.body, 'toggle-slide', { slideId: currentPathName });
    }

    if (intro) {
      intro.style.zIndex = '-1';
      intro.style.opacity = '0';
    }

    const links = document.querySelectorAll('[data-js-track-click]');
    forEach(links, (i, link) => {
      link.addEventListener('click', () => {
        if (link.getAttribute('data-js-track-multiple')) {
          gtag.trackEvent(...link.getAttribute('data-js-track').split(','));
        } else {
          gtag.trackEventOnce(...link.getAttribute('data-js-track').split(','));
        }
      });
    });
  }, 100);
} else {
  if (intro) {
    const gtag = new GtagService();
    intro.style.backgroundImage = `url("${src}")`;
    const image = document.createElement('img');
    image.src = src;

    image.onload = () => {
      intro.classList.add('animate');

      setTimeout(() => {
        if (intro) {
          intro.style.backgroundPosition = '-10% 0';
        }

        setTimeout(() => {
          intro.children[0].classList.add('left');
        }, 6000);

        setTimeout(() => {
          if (isDesktop()) {
            intro.style.backgroundPosition = '-10% 0';
          } else {
            intro.style.backgroundPosition = '-10% 0';
          }
          setTimeout(() => {
            triggerCustom(document.body, 'toggle-slide', { slideId: 'main' });
            intro.style.zIndex = '-1';
            intro.style.opacity = '0';
          }, 3500);
        }, 3000);

        setTimeout(() => {
          intro.children[0].classList.add('center');
        }, 4500);

        const links = document.querySelectorAll('[data-js-track-click]');
        forEach(links, (i, link) => {
          link.addEventListener('click', () => {
            if (link.getAttribute('data-js-track-multiple')) {
              gtag.trackEvent(...link.getAttribute('data-js-track').split(','));
            } else {
              gtag.trackEventOnce(...link.getAttribute('data-js-track').split(','));
            }
          });
        });
      }, 100);
    };
  }
}
