class AudioRecorderService {
  constructor() {
    this.gtag = null;
    this.audio = null;
    this.stream = null;
    this.noAudio = false;
    this.status = 'none';
    this.latestAudio = null;
  }

  requestAudio(gtag) {
    const constraints = {
      audio: true,
      video: false
    };

    if (this.stream) {
      return Promise.resolve(this.stream);
    }

    try {
      return navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        gtag.trackEvent('main', 'mic_allow');
        this.stream = stream;
        return stream;
      });
    } catch (e) {
      gtag.trackEvent('main', 'mic_deny');
      return Promise.reject();
    }
  }

  saveLatestAudio(blob) {
    this.latestAudio = blob;
    this.stream = null;
  }

  createAudio() {
    if(this.latestAudio) {
      this.audio = new Audio(URL.createObjectURL(this.latestAudio));
    }
    // this.audio.play();
  }

  stopAudio() {
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }
}

export default new AudioRecorderService();
